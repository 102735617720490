import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Spin, Form, Tag, Upload, Button } from "antd";
import { useIntl } from "react-intl";
import { Table } from "ant-table-extensions";
import { useEffect } from "react";
import { ALL_GRADES, COLOR_TAGS_BY_GRADE, TERMS_DATE } from "../../Types";
import StudentModalReport from "./Components/StudentModalReport";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { IoAddCircle, IoPulseSharp } from "react-icons/io5";
import { GrAdd } from "react-icons/gr";
import CreateBehavioral from "./Components/CreateBehavioral";
import dayjs from "dayjs";
import FilterTerms from "../../Components/FilterTerms";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const { Dragger } = Upload;

const { Text, Title } = Typography;
const BehavioralReport = ({ type = "ABSENCE" }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { students } = useSelector((state) => state.students);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [formatted, setFormatted] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [filterStudents, setFilterStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [startDate, setStartDate] = useState(
    dayjs(TERMS_DATE.Second, "YYYY-MM-DD").toDate()
  );
  const [endDate, setEndDate] = useState(
    dayjs(TERMS_DATE.Third, "YYYY-MM-DD").subtract(1, "day").toDate()
  );
 
  useEffect(() => {
    if (!students?.length) {
      // getUsersData();
    } else {
      let f = students.map((student) => {
        let countOfBehavioral =  0;
        if(student?.behavioral?.length){
            student?.behavioral?.forEach((b) => {
              const d = dayjs(b.date);
              if (
                dayjs(d).isBetween(
                  dayjs(startDate).format("YYYY-MM-DD"),
                  dayjs(endDate).format("YYYY-MM-DD"),
                  "day"
                )
              ) {
                countOfBehavioral++;
              }

            });
        }
        return {
          key: student.name,
          name: student.name,
          phone: student.phone,
          _id: student._id,
          grade: student?.grade ? student?.grade : "غير محدد",
          behavioral: student?.behavioral ? student?.behavioral : [],
          countOfBehavioral: countOfBehavioral,
          userObject: {
            ...student,
            countOfBehavioral,
          },
        };
      });
      // sort by absenceDays length;
      let sorted = f.sort((a, b) => {
          return b.countOfBehavioral - a.countOfBehavioral;
        });
      
      setFormatted(sorted);
    }
  }, [students, type, startDate, endDate]);
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "grade" }),
      dataIndex: "grade",
      key: "grade",
      width: 200,
      render: (grade) => {
        return <Tag color={COLOR_TAGS_BY_GRADE[grade]}>{grade}</Tag>;
      },
      // filter by grads;
      onFilter: (value, record) => {
        return record.grade === value;
      },
      filters: ALL_GRADES.filter((g) => {
        if (user?.role === "admin") return true;
        return user?.grades?.includes(g) || g === "غير محدد";
      }).map((g) => {
        return {
          text: g,
          value: g,
        };
      }),
    },
    {
      title: "المخالفات السلوكية",
      dataIndex: "countOfBehavioral",
      key: "countOfBehavioral",
      render: (behavioral) => {
        return <Tag color={behavioral > 0 ? "red" : "green"}>{behavioral}</Tag>;
      },
      sorter: (a, b) => a.absenceDays - b.absenceDays,
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      key: "actions",
      // width: 150,
      render: (actions, record) => {
        return (
          <div className='actions-container'>
            <Button
              type='primary'
              onClick={() => {
                setSelectedStudent(record.userObject);
                setShowAddModal(true);
              }}
              icon={<EditOutlined />}
            />
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPhones(selectedRows);
      // setPhones(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.phone ? true : false,
        // Column configuration not to be checked
        // check: record.phone,
      };
    },
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <StudentModalReport
        visible={showAddModal}
        onCancel={() => {
          setShowAddModal(false);
          setSelectedStudent({});
        }}
        student={selectedStudent}
        type={type}
      />
      <CreateBehavioral 
        visible={showCreateModal}
        onCancel={() => {
          setShowCreateModal(false);
         
        }}
        students={phones}
      />
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            المخالفات السلوكية
          </Title>
          <Button
            type='primary'
            disabled={phones?.length === 0}
            onClick={() => {
              console.log("create one");
              console.log(phones);
              setShowCreateModal(true);
            }}
            icon={<IoAddCircle />}
            size="large"
            className="my-font"
          >
            إنشاء مخالفة
          </Button>
        </div>
        <FilterTerms
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          endDate={endDate}
          startDate={startDate}
        />
        <br style={{
          marginBottom: 20
        }}/>
        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || students?.length === 0,
              value: searchValue,
              onChange: (e) => {
                setSearchValue(e.target.value);
                if (e.target.value?.length) {
                  const filtered = formatted.filter((s) => {
                    return (
                      s.name.includes(e.target.value) ||
                      s.phone.includes(e.target.value)
                    );
                  });
                  setFilterStudents(filtered);
                } else {
                  setFilterStudents([]);
                }
              },
            },
          }}
          // exportable
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={searchValue?.length > 0 ? filterStudents : formatted}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {
          //       setSelectedStudent(record.userObject);
          //       setShowAddModal(true);
          //     }, // click row
          //     className: "pointer",
          //   };
          // }}
        />
      </Spin>
    </div>
  );
};

export default BehavioralReport;
