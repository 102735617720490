import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  thirdStudents: [],
};

export const restaurantsSlice = createSlice({
  name: "students",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStudents: (state, action) => {
      state.students = action.payload;
    },
    deleteStudent: (state, action) => {
      state.students = state.students.filter((student) => student._id !== action.payload);
    },
    setThirdStudents: (state, action) => {
      state.thirdStudents = action.payload;
    },
    editThirdStudents: (state, action) => {
      state.thirdStudents = state.thirdStudents.map((student) => {
        if (student._id === action.payload._id) {
          return action.payload;
        }
        return student;
      });
    },
    editStudent : (state, action) => {
      state.students = state.students.map((student) => {
        if (student._id === action.payload._id) {
          return action.payload;
        }
        return student;
      });
    }
  },
});

export const {
  setStudents: setStudentsAction,
  deleteStudent: deleteStudentAction,
  setThirdStudents: setThirdStudentsAction,
  editThirdStudents: editThirdStudentsAction,
  editStudent: editStudentAction
} = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
