import { notification, } from "antd";

const openNotification = ({ type, title, description, rtl }) => {
    notification[type]({
      message: title,
      description: description,
      placement: rtl ? "topRight" : "topLeft",
    });
  };

export default openNotification
