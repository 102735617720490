import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Spin, Form, Input, Upload, Tag } from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessages, sendMessageWithImage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { FORMATTED_ADMIN_PHONES, roles, rolesColors } from "./data";

const { Dragger } = Upload;

const { Text, Title } = Typography;
const DailyWatch = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      render: (name) => {
        return <Text>{name}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "role" }),
      dataIndex: "role",
      key: "role",
      render: (role) => {
        return <Tag color={rolesColors[role]}>{role}</Tag>;
      },
      filters: Object.values(roles).map((role) => {
        return {
          text: role,
          value: role,
        };
      }),
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
  ];

  const sendSMS = async (values) => {
    try {
      if (!phones?.length) return;

      let formattedMessageWithPhone = phones.map((phone) => {
        return {
          phone: phone.phone,
          message: `السلام عليكم\n نود تذكيركم بالإشراف اليومي للفسحة  والصلاة لهذا اليوم \nثانوية الملك عبدالله`,
        };
      });
      setLoading(true);

      const data = {
        phones: formattedMessageWithPhone,
        senderName: "Whatsapp",
        studentIds: [],
        type: "GENERAL",
      };
      openNotification({
        title: `سيستغرق ارسال الرسائل ${phones.length} ثواني`,
        type: "warning",
        rtl,
      });
      const res = await sendMessages(data);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPhones(selectedRows);
      // setPhones(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.phone ? true : false,
        // Column configuration not to be checked
        // check: record.phone,
      };
    },
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            الإشراف اليومي
          </Title>
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendSMS}
          initialValues={{
            senderName: "Whatsapp",
          }}
        >
          {() => {
            return (
              <>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading || !phones?.length}
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || FORMATTED_ADMIN_PHONES?.length === 0,
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={FORMATTED_ADMIN_PHONES}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default DailyWatch;
