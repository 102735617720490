import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import openNotification from "../../Components/Notifications";
import moment from "moment";
import QrReader from "react-qr-scanner";
import { useIntl } from "react-intl";
import { Typography, Table, Spin } from "antd";
import CustomButton from "../../Components/CustomButton";
import { IoQrCodeOutline } from "react-icons/io5";
import { canOpenRearCamera } from "./helpers";
import StudentModal from "../StudentPoints/Components/StudentModal";
const { Text } = Typography;

const QRReader = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [showEditReservation, setShowEditReservation] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const { students } = useSelector((state) => state.students);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // handle reader QR;
  const handleScan = async (data) => {
    if (data && data?.text) {
      // setAudioError(undefined);
      const student = students.find((res) => res._id === data?.text);
      // await new Promise((r) => setTimeout(r, 100));
      if (student) {
        setShowScanner(false);
        let copy = { ...student };
        // if(copy.appUserId){
        //   copy.customerId = copy.appUserId;
        // }
        setSelectedStudent(copy);
        setShowEditReservation(true);
      } else {
        setShowScanner(false);

        openNotification({
          type: "error",
          message: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "reservationNotFound" }),
          rtl,
        });
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
      }}
    >
      <StudentModal
        visible={showEditReservation}
        onCancel={() => {
          setShowEditReservation(false);
          setSelectedStudent({});
        }}
        student={selectedStudent}
      />
      {showScanner ? (
        <QrReader
          delay={300}
          style={{
            width: "100%",
            maxHeight: 300,
          }}
          onError={(erro) => {
            console.log(erro);
          }}
          onScan={handleScan}
          legacyMode={true}
          constraints={
            canOpenRearCamera()
              ? {
                  video: {
                    facingMode: { exact: `environment` },
                  },
                }
              : undefined
          }
        />
      ) : null}
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CustomButton
            text={showScanner ? "إخفاء" : "إظهار"}
            onClick={() => {
              setShowScanner(!showScanner);
            }}
            icon={
              <IoQrCodeOutline
                style={{
                  marginLeft: 10,
                  fontSize: 15,
                }}
              />
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            type='primary'
            className='px-3 border-5 mb-3 mt-3'
          />
        </div>
      </Spin>
    </div>
  );
};

export default QRReader;
