import {
  Modal,
  Typography,
  Form,
  Spin,
  Row,
  Col,
  Input,
  Divider,
  Tag,
  Checkbox,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { createBehavioralReport,  } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import list from "../list.json";
const { Title, Text } = Typography;
const CreateBehavioral = ({ visible, onCancel, students }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log("Values", values);
      console.log("students", students);
      const selected = list.find((item) => item.type === values.type);
      console.log(selected.grade);
      const allStudents = students.map((student) => {
        return {
          _id: student._id,
          name: student.name,
          grade: student.grade,
          localId: student.localId,
        };
      });
      const behavioral = {
        type: values.type,
        grade: selected.grade,
        text: values.value,
        date: new Date(),
      };
      const data = {
        students: allStudents,
        reportData: {
          file: "report.pdf",
        },
        behavioral,
      };

        openNotification({
          title: `سيستغرق إصدار المخالفات ${allStudents?.length} ثواني`,
          type: "warning",
          rtl,
        });

        const res = await createBehavioralReport(data);
        if (res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `report.pdf`);
          document.body.appendChild(link);
          link.click();
          form.resetFields();
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            description: 'تم إصدار المخالفة بنجاح',
            rtl,
          });
          onCancel();
        } else {
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            description: intl.formatMessage({ id: "errorfetch" }),
            type: "error",
            rtl,
          });
        }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      console.log(err);
    }
  };

  const printReport = async () => {
    // console.log("Print");
    // setLoading(true);
    // try {
    //   const data = {
    //     name: student?.name,
    //     dates: selectedDates,
    //     grad: student?.grade,
    //     type: type === "ABSENCE" ? "absence" : "late",
    //     file: "report.pdf",
    //   };
    //   // get the pdf from the server;
    //   const res = await getPdfReport(data);
    //   const url = window.URL.createObjectURL(new Blob([res.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", `${student?.name}-report.pdf`);
    //   document.body.appendChild(link);
    //   link.click();
    //   setLoading(false);
    // } catch (e) {
    //   console.log("error");
    //   openNotification({
    //     title: intl.formatMessage({ id: "error" }),
    //     description: intl.formatMessage({ id: "errorfetch" }),
    //     type: "error",
    //     rtl,
    //   });
    //   setLoading(false);
    // }
  };

  return (
    <Modal
      title={
        <>
          <Title level={5} className='my-font'>
            إنشاء مخالفة سلوكية
          </Title>
        </>
      }
      centered
      open={visible}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => onCancel()}
      footer={null}
      width={"80%"}
    >
      <Spin spinning={loading}>
        <Form
          name='student-behavioral'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            date: dayjs(),
            type: "",
            value: "",
            grade: "",
          }}
        >
          {() => {
            return (
              <>
                <Form.Item
                  name='type'
                  label='نوع المخالفة'
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب",
                    },
                  ]}
                >
                  <Select
                    className='my-font'
                    placeholder='اختر نوع المخالفة'
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                  >
                    {list.map((item) => {
                      return (
                        <Select.Option
                          className='my-font'
                          value={item.type}
                          key={item.type}
                        >
                          {item.type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='value'
                  label='نص المخالفة'
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب",
                    },
                  ]}
                >
                  <Select
                    className='my-font'
                    placeholder='اختر نص المخالفة'
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    onChange={(value) => {
                      // get the type from list;
                      let i = list.findIndex((item) =>
                        item.list.includes(value)
                      );
                      if (i !== -1) {
                        form.setFieldsValue({ type: list[i].type });
                      }
                    }}
                  >
                    {list
                      .filter((val) => {
                        if (form.getFieldValue("type")) {
                          return val.type === form.getFieldValue("type");
                        } else {
                          return true;
                        }
                      })
                      .map((item) => {
                        return item.list.map((one, index) => {
                          return (
                            <Select.Option
                              className='my-font'
                              value={one}
                              key={one}
                            >
                              {index + 1} - {one}
                            </Select.Option>
                          );
                        });
                      })}
                  </Select>
                </Form.Item>

                <Row
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={10}>
                    <CustomButton
                      type='primary'
                      htmlType='submit'
                      disabled={loading}
                      className='my-font mt-3'
                      style={{ width: "100%" }}
                      loading={loading}
                      text={"إصدار المخالفة"}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateBehavioral;
