import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  Input,
  Select,
  Tag,
  Upload,
  Button,
} from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessages } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import * as XLSX from "xlsx";
import { useEffect } from "react";
import dayjs from "dayjs";
import { ALL_GRADES, COLOR_TAGS_BY_GRADE, TERMS_DATE } from "../../Types";
import FilterTerms from "../../Components/FilterTerms";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const { Dragger } = Upload;

const { Text, Title } = Typography;
const Latents = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { students } = useSelector((state) => state.students);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [formatted, setFormatted] = useState([]);
  const [filterStudents, setFilterStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs(TERMS_DATE.Second, "YYYY-MM-DD").toDate()
  );
  const [endDate, setEndDate] = useState(
    dayjs(TERMS_DATE.Third, "YYYY-MM-DD").subtract(1, "day").toDate()
  );
  const convertArabicToEnglish = (string) => {
    let arabic = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    // loop throw the string and replace any number with english number;
    let english = string;
    arabic.forEach((number, index) => {
      english = english.replace(new RegExp(number, "g"), index);
    });
    return english;
  };
  useEffect(() => {
    if (!students?.length) {
      // getUsersData();
    } else {
      let f = students.map((student) => {
        let length = 0;
        if (student?.latentsDays?.length) {
          student?.latentsDays?.forEach((day) => {
            const converted = convertArabicToEnglish(day);
            if (
              dayjs(converted, "YYYY-MM-DD").isBetween(
                dayjs(startDate).format("YYYY-MM-DD"),
                dayjs(endDate).format("YYYY-MM-DD"),
                "day"
              )
            ) {
              length++;
            }
          });
        }
        return {
          key: student.name,
          name: student.name,
          phone: student.phone,
          _id: student._id,
          grade: student?.grade ? student?.grade : "غير محدد",
          latentsDays: length,
        };
      });
      setFormatted(f);
    }
  }, [students, startDate, endDate]);
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
      // filter selected rows;
      onFilter: (value, record) => {
        let found = phones.findIndex((p) => p.phone === record.phone) !== -1;
        if (value === "TRUE") {
          return found;
        } else {
          return !found;
        }
      },
      filters: [
        {
          text: "تم اختيارهم",
          value: "TRUE",
        },
        {
          text: "لم يتم اختيارهم",
          value: "FALSE",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },

    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "grade" }),
      dataIndex: "grade",
      key: "grade",
      render: (grade) => {
        return <Tag color={COLOR_TAGS_BY_GRADE[grade]}>{grade}</Tag>;
      },
      // filter by grads;
      onFilter: (value, record) => {
        return record.grade === value;
      },
      filters: ALL_GRADES.filter((g) => {
        if (user?.role === "admin") return true;
        return user?.grades?.includes(g) || g === "غير محدد";
      }).map((g) => {
        return {
          text: g,
          value: g,
        };
      }),
    },
    {
      title: "عدد أيام التأخر",
      dataIndex: "latentsDays",
      key: "latentsDays",
      render: (latentsDays) => {
        return (
          <Tag color={latentsDays > 0 ? "red" : "green"}>{latentsDays}</Tag>
        );
      },
      sorter: (a, b) => a.latentsDays - b.latentsDays,
    },
  ];

  const sendSMS = async (values) => {
    try {
      if (!phones?.length) return;
      let studentIds = phones.map((phone) => phone._id);
      let formattedMessageWithPhone = phones.map((phone) => {
        return {
          phone: phone.phone,
          message: `عزيزي ولي أمر الطالب ${
            phone.name
          } نفيدكم بتأخر الطالب لهذا اليوم ${dayjs().format("DD/MM/YYYY")}`,
        };
      });
      setLoading(true);
      const data = {
        phones: formattedMessageWithPhone,
        senderName: "Whatsapp",
        studentIds,
        type: "LATENTS",
      };
      openNotification({
        title: `سيستغرق ارسال الرسائل ${phones.length} ثواني`,
        type: "warning",
        rtl,
      });
      const res = await sendMessages(data);
      // console.log(res.data);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPhones(selectedRows);
      // setPhones(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.phone ? true : false,
        // Column configuration not to be checked
        // check: record.phone,
      };
    },
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "latents" })}
          </Title>
          <CustomButton
            onClick={sendSMS}
            type='primary'
            className={"border-8 px-2 mx-3"}
            text='إرسل الرسالة'
            disabled={loading || phones?.length === 0}
          />
        </div>
        <FilterTerms
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          endDate={endDate}
          startDate={startDate}
        />
        <br />
        <Text type='secondary'>
          نموذج الرسالة : "عزيزي ولي أمر الطالب xxxxx نفيدكم بتأخر الطالب لهذا
          اليوم {dayjs().format("DD/MM/YYYY")}
        </Text>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || students?.length === 0,
              value: searchValue,
              onChange: (e) => {
                setSearchValue(e.target.value);
                if (e.target.value?.length) {
                  const filtered = formatted.filter((s) => {
                    return (
                      s.name.includes(e.target.value) ||
                      s.phone.includes(e.target.value)
                    );
                  });
                  setFilterStudents(filtered);
                } else {
                  setFilterStudents([]);
                }
              },
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={searchValue?.length > 0 ? filterStudents : formatted}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default Latents;
