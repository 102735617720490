import React, { useState } from "react";
import { Form, Image, Layout, Typography, Input, Divider, Table } from "antd";
import LOGO from "../../assets/images/logo.png";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import axios from "axios";
import COLORS from "../../Style/colors";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ALL_STUDENTS from "./DATA.json";
const { Title, Text } = Typography;
const { Content, Header, Footer } = Layout;
const Slock = () => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { students } = useSelector((state) => state.students);
  const [studentName, setStudentName] = useState("");
  const [className, setClassName] = useState("");
  const [values, setValues] = useState([]);
  // let errors = [
  //   {
  //     id: "1138027410",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 95,
  //     },
  //   },
  //   {
  //     id: "1128529300",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 95,
  //     },
  //   },
  //   {
  //     id: "1134608411",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 95,
  //     },
  //   },
  //   {
  //     id: "1135260204",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 94,
  //     },
  //   },
  //   {
  //     id: "2294528738",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 89,
  //     },
  //   },
  //   {
  //     id: "1136483854",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 94,
  //     },
  //   },
  //   {
  //     id: "1143203964",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 94,
  //     },
  //   },
  //   {
  //     id: "1139545352",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 94,
  //     },
  //   },
  //   {
  //     id: "1140229863",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 94,
  //     },
  //   },
  //   {
  //     id: "1141662682",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 82,
  //     },
  //   },
  //   {
  //     id: "1143527545",
  //     values: {
  //       solock: 100,
  //       mowadhaba: 82,
  //     },
  //   },
  //   {
  //     id: "1136202973",
  //     values: {
  //       solock: 93,
  //       mowadhaba: 100,
  //     },
  //   },
  //   {
  //     id: "1138990559",
  //     values: {
  //       solock: 93,
  //       mowadhaba: 100,
  //     },
  //   },
  //   {
  //     id: "1137684120",
  //     values: {
  //       solock: 85,
  //       mowadhaba: 100,
  //     },
  //   },
  //   {
  //     id: "1160621817",
  //     values: {
  //       solock: 90,
  //       mowadhaba: 100,
  //     },
  //   },
  //   {
  //     id: "1138352131",
  //     values: {
  //       solock: 87,
  //       mowadhaba: 100,
  //     },
  //   },
  // ];
  const [shows, setShows] = useState(false);
  const onFinish = async (val) => {
    try {
      setLoading(true);
      // check if the id is valied;
      // const id = values.localId;
      // // https://schoolmansour.s3.me-south-1.amazonaws.com/result/1139352379.pdf

      // //https://ntaej.s3.me-south-1.amazonaws.com/result/1139697674.pdf
      // const res = await axios.get(
      //   `https://schoolmansour.s3.me-south-1.amazonaws.com/result/${id}.pdf`,
      //   {
      //     responseType: "blob",
      //   }
      // );

      // // console.log(res?.status);
      // if (res?.status === 200) {
      //   // window.open(`https://schoolmansour.s3.me-south-1.amazonaws.com/result/${id}.pdf`, "_blank")
      //   const url = window.URL.createObjectURL(new Blob([res.data]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", `${id}.pdf`);
      //   document.body.appendChild(link);
      //   link.click();
      // } else {
      //   openNotification({
      //     title: "خطأ",
      //     description: "لم نتمكن من  إيجاد نتيجة الطالب",
      //     type: "error",
      //     rtl: true,
      //   });
      // }
      // check if the id in the error;
      // const inError = errors.find((a) => a.id === val.localId);
      // if (inError?.id) {
      //   let errValues = inError.values;
      //   let tmp = [...values];
      //   tmp[0].score = errValues.mowadhaba;
      //   tmp[1].score = errValues.solock;
      //   // get name;
      //   let sName = "";
      //   const s1 = students.find((s) => s.localId === val.localId);
      //   if (s1?.name) {
      //     sName = s1.name;
      //   }
      //   setStudentName(sName);
      //   setValues(tmp);
      // } else {
      const s = ALL_STUDENTS.find(
        (s) => `${s.id}`?.trim() === `${val.localId?.trim()}`
      );
      // const s = students.find((s) => s.localId === val.localId);
      // setStudentName(s.name);
      if (s) {
        setStudentName(s.Name);
        setClassName(s.ClassName?.split("-")[0]);
        setValues([
          {
            title: "المواظبة",
            score: s.mowadhaba,
            key: "11",
          },
          {
            title: "السلوك",
            score: s.solock,
            key: "22",
          },
        ]);
      } else {
        openNotification({
          title: "خطأ",
          description: "لم نتمكن من  إيجاد نتيجة الطالب",
          type: "error",
          rtl: true,
        });
        setLoading(false);
        return;
      }

      await new Promise((r) => setTimeout(r, 100));
      setShows(true);
      setLoading(false);
    } catch (err) {
      console.log(err?.response?.status);
      if (err?.response?.status === 403) {
        openNotification({
          title: "خطأ",
          description: "لم نتمكن من  إيجاد رقم الهوية الخاص بالطالب",
          type: "error",
          rtl: true,
        });
      } else {
        openNotification({
          title: "خطأ",
          description: "حدث خطأ ما ، الرجاء المحاولة لاحقاً",
          type: "error",
          rtl: true,
        });
      }
      setLoading(false);
    }
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Content
        style={{
          flex: 2,
          display: "flex",
          //   justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <Image
          preview={false}
          width={120}
          src={LOGO}
          className='logo-Login'
          style={{
            // Add shadows to create the "card" effect
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
        />
        <Title
          style={{
            textAlign: "center",
          }}
          level={5}
        >
          {" "}
          الاستعلام عن نتائج عن درجات السلوك والمواظبة
        </Title>
        <Form
          name='student-form'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          {() => {
            return (
              <div>
                <Form.Item
                  name='localId'
                  label={"رقم الهوية الخاص بالطالب"}
                  rules={[
                    {
                      required: true,
                      message: "رقم الهوية مطلوب",
                    },
                    {
                      validator: (_, value) => {
                        // make sure that value is a number only;
                        if (value && isNaN(value)) {
                          return Promise.reject(
                            new Error("يجب أن يكون الرقم رقماً فقط")
                          );
                        }

                        // if (value && value.length < 10) {
                        //   return Promise.reject(
                        //     new Error("يجب أن يكون الرقم 10 أرقام")
                        //   );
                        // }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    // addonBefore={rtl ? null : <Text>966</Text>}
                    // addonAfter={rtl ? <Text>966</Text> : null}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Form.Item>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  className='my-font mt-3'
                  //   style={{ }}
                  loading={loading}
                  text={"استعلام"}
                  style={{
                    // Add shadows to create the "card" effect
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    width: "100%",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                />
              </div>
            );
          }}
        </Form>

        <Divider />
        {shows ? (
          <Title level={5}>
            {studentName} / {className}
          </Title>
        ) : null}
        {shows ? (
          <Table
            style={{
              width: "100%",
            }}
            className='myt'
            columns={[
              {
                title: "المادة",
                key: "title",
                dataIndex: "title",
              },
              {
                title: "الدرجة",
                key: "score",
                dataIndex: "score",
              },
            ]}
            dataSource={values}
            pagination={false}
          />
        ) : null}
      </Content>
      <Footer
        style={{
          flex: 1,
          backgroundColor: COLORS.primary,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Add whatsaap icon */}
        <div
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px",
          }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=966114713663",
              "_blank"
            );
          }}
        >
          <Text style={{ color: COLORS.primary }}>
            للمساعدة تواصل معنا
            <WhatsAppOutlined
              style={{
                color: COLORS.primary,
                fontSize: "20px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
          </Text>
        </div>
        <Text
          style={{
            color: "#fff",
          }}
          className='my-font'
        >
          جميع الحقوق محفوظة لمجمع الملك عبدالله © {new Date().getFullYear()}
        </Text>
      </Footer>
    </Layout>
  );
};

export default Slock;
