import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  Input,
  Select,
  Tag,
  Upload,
  Button,
} from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessages, sendMessageWithImage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import * as XLSX from "xlsx";
import { useEffect } from "react";
import { ALL_GRADES, COLOR_TAGS_BY_GRADE } from "../../Types";

const { Dragger } = Upload;

const { Text, Title } = Typography;
const WhatsappSMS = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { students } = useSelector((state) => state.students);
  const { user } = useSelector((state) => state.auth);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [file, setFile] = useState(null);
  const [formatted, setFormatted] = useState([]);
  const [filterStudents, setFilterStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    if (!students?.length) {
      // getUsersData();
    } else {
      let f = students.map((student) => {
        return {
          key: student.name,
          name: student.name,
          phone: student.phone,
          grade: student?.grade ? student?.grade : "غير محدد",
        };
      });
      setFormatted(f);
    }
  }, [students]);
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
      // filter selected rows;
      onFilter: (value, record) => {
        let found = phones.findIndex((p) => p.phone === record.phone) !== -1;
        if (value === "TRUE") {
          return found;
        } else {
          return !found;
        }
      },
      filters: [
        {
          text: "تم اختيارهم",
          value: "TRUE",
        },
        {
          text: "لم يتم اختيارهم",
          value: "FALSE",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "grade" }),
      dataIndex: "grade",
      key: "grade",
      render: (grade) => {
        return <Tag color={COLOR_TAGS_BY_GRADE[grade]}>{grade}</Tag>;
      },
      // filter by grads;
      onFilter: (value, record) => {
        return record.grade === value;
      },
      filters: ALL_GRADES.filter((g) => {
        if (user?.role === "admin") return true;
        return user?.grades?.includes(g) || g === "غير محدد";
      }).map((g) => {
        return {
          text: g,
          value: g,
        };
      }),
    },
  ];

  const dummyRequest = (props) => {
    setTimeout(() => {
      props.onSuccess("ok");
      setFile(props.file);
    }, 0);
  };
  const sendSMS = async (values) => {
    try {
      if (!phones?.length) return;
      if (file) {
        return sendWithImage(values, file);
      }
      let formattedMessageWithPhone = phones.map((phone) => {
        let formattedMessage = values.message;
        if(formattedMessage.includes("{studentName}")) {
          formattedMessage = formattedMessage.replace("{studentName}", phone.name);
        }
        return {
          phone: phone.phone,
          message: formattedMessage,
        };
      });
      setLoading(true);

      const data = {
        phones: formattedMessageWithPhone,
        senderName: "Whatsapp",
        studentIds: [],
        type: "GENERAL",
      };
      openNotification({
        title: `سيستغرق ارسال الرسائل ${phones.length} ثواني`,
        type: "warning",
        rtl,
      });
      const res = await sendMessages(data);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const sendWithImage = async (values, file) => {
    try {
      // send the image with the message;
      if (!phones?.length) return;
      // loop throw the phones and send the message;
      setLoading(true);
      let formattedMessageWithPhone = phones.map((phone) => {
        let formattedMessage = values.message;
        if(formattedMessage.includes("{studentName}")) {
          formattedMessage = formattedMessage.replace("{studentName}", phone.name);
        }
        return {
          phone: phone.phone,
          message: formattedMessage,
        };
      });

      const formData = new FormData();
      formData.append("phones", JSON.stringify(formattedMessageWithPhone));
      formData.append("file", file);
      openNotification({
        title: `سيستغرق ارسال الرسائل ${phones.length} ثواني`,
        type: "warning",
        rtl,
      });
      const res = await sendMessageWithImage(formData);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPhones(selectedRows);
      // setPhones(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.phone ? true : false,
        // Column configuration not to be checked
        // check: record.phone,
      };
    },
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "students" })}
          </Title>
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendSMS}
          initialValues={{
            senderName: "Whatsapp",
          }}
        >
          {() => {
            return (
              <>
                <CustomButton
                  text='إدراج اسم الطالب'
                  className={`btnRegister btn-text border-8`}
                  type='secondary'
                  onClick={() => {
                    form.setFieldsValue({
                      message: form?.getFieldValue("message") + " {studentName}",
                    });
                  }}
                />
                <Form.Item
                  name='message'
                  label={intl.formatMessage({ id: "message" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>

                {/* Add File upload input image */}
                <Text className='my-font'>أضف صورة</Text>
                <Dragger
                  accept='image/jpg,image/jpeg,image/png,application/pdf'
                  // action='/upload.do'
                  listType='picture-card'
                  customRequest={dummyRequest}
                  maxCount={1}
                >
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      تحميل
                    </div>
                  </div>
                </Dragger>

                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      !phones?.length ||
                      !form?.getFieldValue("message")
                    }
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || students?.length === 0,
              value: searchValue,
              onChange: (e) => {
                setSearchValue(e.target.value);
                if (e.target.value?.length) {
                  const filtered = formatted.filter((s) => {
                    return (
                      s.name.includes(e.target.value) ||
                      s.phone.includes(e.target.value)
                    );
                  });
                  setFilterStudents(filtered);
                } else {
                  setFilterStudents([]);
                }
              },
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={searchValue?.length > 0 ? filterStudents : formatted}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default WhatsappSMS;
