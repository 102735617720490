import { Layout, Typography, Image } from "antd";
import React from "react";
import COLORS from "../../Style/colors";
import LOGO from "../../assets/images/logo.png";
import { IoCheckboxOutline } from "react-icons/io5";
import { WhatsAppOutlined } from "@ant-design/icons";
const { Content, Footer } = Layout;
const { Title, Text } = Typography;
function SuccessView({ student }) {
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Content
        style={{
          flex: 2,
          display: "flex",
          //   justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: COLORS.primary,
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}
        >
          <Text
            className='my-font'
            style={{
              textAlign: "center",
              color: "#fff",
              width: "60%",
            }}
          >
            {student?.nameAr}
          </Text>
          <Image
            preview={false}
            width={50}
            src={LOGO}
            style={{
              borderRadius: "10px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
            backgroundColor: COLORS.white,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "20px",
            width: "100%",
            flex: 1,
          }}
        >
          <IoCheckboxOutline
            style={{
              fontSize: "50px",
              color: COLORS.primary,
            }}
          />
          <Title
            level={4}
            style={{
              color: COLORS.primary,
              marginTop: "20px",
            }}
          >
            تم حفظ المعلومات بنجاح
          </Title>
        </div>
      </Content>
      <Footer
        style={{
          flex: 1,
          backgroundColor: COLORS.primary,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Add whatsaap icon */}
        <div
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px",
          }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=966114713663",
              "_blank"
            );
          }}
        >
          <Text style={{ color: COLORS.primary }}>
            للمساعدة تواصل معنا
            <WhatsAppOutlined
              style={{
                color: COLORS.primary,
                fontSize: "20px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
          </Text>
        </div>
        <Text
          style={{
            color: "#fff",
          }}
          className='my-font'
        >
          جميع الحقوق محفوظة لمجمع الملك عبدالله © {new Date().getFullYear()}
        </Text>
      </Footer>
    </Layout>
  );
}

export default SuccessView;
