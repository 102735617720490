import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form, Upload,
  Button
} from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessages, } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import * as XLSX from "xlsx";
const { Dragger } = Upload;


const { Title, Text } = Typography;
const Exam = () => {
    const intl = useIntl();
    const { rtl } = useSelector((state) => state.settings);
    const { students } = useSelector((state) => state.students);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [phones, setPhones] = useState([]);
    const [formatted, setFormatted] = useState([]);
   
    const columns = [
      {
        title: "#",
        dataIndex: "number",
        key: "number",
        width: 80,
        render: (number, record, index) => {
          return <Text>{index + 1}</Text>;
        },
      },
      {
        title: intl.formatMessage({ id: "justName" }),
        dataIndex: "name",
        key: "name",
        width: 160,
        render: (name, record) => {
          return <Text>{name}</Text>;
        },
        sorter:(a,b)=>a.name.localeCompare(b.name),
      },
      
      {
        title: intl.formatMessage({ id: "phone" }),
        dataIndex: "phone",
        key: "phone",
        render: (phone) => {
          return <Text>{phone}</Text>;
        },
      },
      {
        title: intl.formatMessage({ id: "message" }),
        dataIndex: "message",
        key: "message",
        
      },
     
  
    ];
  
    const sendSMS = async (values) => {
      try {
        if (!phones?.length) return;
        let studentIds = [];
        let formattedMessageWithPhone = phones.map((phone) => {
          return {
            phone: phone.phone,
            message: phone.message,
          };
        });
        setLoading(true);
        const data = {
          phones: formattedMessageWithPhone,
          senderName: "Whatsapp",
          studentIds: [],
          type: 'GENERAL'
        };
        openNotification({
          title: `سيستغرق ارسال الرسائل ${phones.length} ثواني`,
          type: 'warning',
          rtl,
        });
        const res = await sendMessages(data);
        // console.log(res.data);
        
        if (res.data.status === "success") {
          form.resetFields();
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
            rtl,
          });
        }
  
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    };
  
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRows);
        setPhones(selectedRows);
        // setPhones(phones);
      },
    //   getCheckboxProps: (record) => {
    //     return {
    //       disabled: !record.phone ? true : false,
    //       // Column configuration not to be checked
    //       // check: record.phone,
    //     };
    //   },
    };

    const onChange = (e) => {
        e.preventDefault();
        var files = e.target.files,
          f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          let readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
    
          let formatted = [];
          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
          // console.log(dataParse);
          // format should be like this ==> 966509111136
          dataParse.forEach((arr, index) => {
            if (index === 0) return;
            // console.log(arr)
            const [name, colum, seat, lagnah, phone] = arr;
            let message  = `المكرم ولي امر الطالب
رقم جلوس ${name} 
${lagnah} 
عمود ${colum} 
مقعد ${seat} 
ثانوية الملك عبدالله`
            formatted.push({
                name,
                phone,
                message,
                key: index,
            });
           
          });
          // console.log(formatted);
          setFormatted(formatted);
        };
        reader.readAsBinaryString(f);
      };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
        <Button
            className='upload-wrap  border-8'
            onClick={() => {
              const a = document.getElementById("my-upload-excel");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload-excel'
              accept='.xlsx, .xls'
              onChange={onChange}
            />
            <span className='upload-text'>رفع ملف اكسل للطلاب</span>
          </Button>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            رسائل الاختبارات
          </Title>
          <CustomButton
            onClick={sendSMS}
            type='primary'
            className={"border-8 px-2 mx-3"}
            text='إرسل الرسالة'
            disabled={loading || phones?.length === 0}
          />
        </div>
       

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || students?.length === 0,
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formatted}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default Exam;
