import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form, Tag,
  Upload
} from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { useEffect } from "react";
import dayjs from "dayjs";
import { ALL_GRADES, COLOR_TAGS_BY_GRADE, TERMS_DATE } from "../../Types";
import StudentModalReport from "./Components/StudentModalReport";
import FilterTerms from "../../Components/FilterTerms";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

const { Dragger } = Upload;

const { Text, Title } = Typography;
const AbsenceReport = ({ type = "ABSENCE" }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { students } = useSelector((state) => state.students);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [formatted, setFormatted] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [filterStudents, setFilterStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs(TERMS_DATE.Second, "YYYY-MM-DD").toDate()
  );
  const [endDate, setEndDate] = useState(
    dayjs(TERMS_DATE.Third, "YYYY-MM-DD").subtract(1, "day").toDate()
  );
  const convertArabicToEnglish = (string) => {
    let arabic = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    // loop throw the string and replace any number with english number;
    let english = string;
    arabic.forEach((number, index) => {
      english = english.replace(new RegExp(number, "g"), index);
    });
    return english;
  };
  useEffect(() => {
    if (!students?.length) {
      // getUsersData();
    } else {
      let f = students.map((student) => {
        let absenceDays = [];
        let latentsDays = [];
        if (student?.absenceDays?.length) {
         
          student?.absenceDays?.forEach((day) => {
            const converted = convertArabicToEnglish(day);
            if (
              dayjs(converted, "YYYY-MM-DD").isBetween(
                dayjs(startDate).format("YYYY-MM-DD"),
                dayjs(endDate).format("YYYY-MM-DD"),
                "day"
              )
            ) {
            absenceDays.push(converted);
            }
          });
        }
        if (student?.latentsDays?.length) {
          
          student?.latentsDays?.forEach((day) => {
            const converted = convertArabicToEnglish(day);
            if (
              dayjs(converted, "YYYY-MM-DD").isBetween(
                dayjs(startDate).format("YYYY-MM-DD"),
                dayjs(endDate).format("YYYY-MM-DD"),
                "day"
              )
            ) {
              latentsDays.push(converted);
            }
          });
        }
        return {
          key: student.name,
          name: student.name,
          phone: student.phone,
          _id: student._id,
          grade: student?.grade ? student?.grade : "غير محدد",
          absenceDays: absenceDays?.length ? absenceDays?.length : 0,
          latentsDays: latentsDays?.length ? latentsDays?.length : 0,
          userObject: {
            ...student,
            absenceDays,
            latentsDays,
          },
          // filterAbsenceDays: absenceDays,
        };
      });
      // sort by absenceDays length;
      let sorted;
      if (type === "ABSENCE") {
        sorted = f.sort((a, b) => {
          return b.absenceDays - a.absenceDays;
        });
      } else {
        sorted = f.sort((a, b) => {
          return b.latentsDays - a.latentsDays;
        });
      }
      setFormatted(sorted);
    }
  }, [students, type, startDate, endDate]);
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "grade" }),
      dataIndex: "grade",
      key: "grade",
      width: 200,
      render: (grade) => {
        return <Tag color={COLOR_TAGS_BY_GRADE[grade]}>{grade}</Tag>;
      },
      // filter by grads;
      onFilter: (value, record) => {
        return record.grade === value;
      },
      filters: ALL_GRADES.filter((g) => {
        if (user?.role === "admin") return true;
        return user?.grades?.includes(g) || g === "غير محدد";
      }).map((g) => {
        return {
          text: g,
          value: g,
        };
      }),
    },
    {
      title: type === "ABSENCE" ? "عدد أيام الغياب" : "عدد أيام التأخر",
      dataIndex: type === "ABSENCE" ? "absenceDays" : "latentsDays",
      key: type === "ABSENCE" ? "absenceDays" : "latentsDays",
      render: (absenceDays) => {
        return (
          <Tag
            color={
              absenceDays > 0
                ? type === "ABSENCE"
                  ? "red"
                  : "orange"
                : "green"
            }
          >
            {absenceDays}
          </Tag>
        );
      },
      sorter: (a, b) => a.absenceDays - b.absenceDays,
    },
    // {
    //   title: intl.formatMessage({ id: "actions" }),
    //   dataIndex: "actions",
    //   key: "actions",
    //   // width: 150,
    //   render: (actions, record) => {
    //     return (
    //       <div className='actions-container'>
    //         <Button
    //           type='primary'
    //           onClick={() => {
    //             setSelectedStudent(record.userObject);
    //             setShowAddModal(true);
    //           }}
    //           icon={<EditOutlined />}
    //         />
    //       </div>
    //     );
    //   }
    // }
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <StudentModalReport
        visible={showAddModal}
        onCancel={() => {
          setShowAddModal(false);
          setSelectedStudent({});
        }}
        student={selectedStudent}
        type={type}
      />
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "absenceReport" })}
          </Title>
        </div>
        <FilterTerms
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          endDate={endDate}
          startDate={startDate}
        />
        <br style={{
          marginBottom: 20
        }}/>
        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || students?.length === 0,
              value: searchValue,
              onChange: (e) => {
                setSearchValue(e.target.value);
                if (e.target.value?.length) {
                  const filtered = formatted.filter((s) => {
                    return (
                      s.name.includes(e.target.value) ||
                      s.phone.includes(e.target.value)
                    );
                  });
                  setFilterStudents(filtered);
                } else {
                  setFilterStudents([]);
                }
              },
            },
          }}
          // exportable
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={searchValue?.length > 0 ? filterStudents : formatted}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelectedStudent(record.userObject);
                setShowAddModal(true);
              }, // click row
              className: "pointer",
            };
          }}
        />
      </Spin>
    </div>
  );
};

export default AbsenceReport;
