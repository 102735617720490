import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk'
import reducers from './reducers';


const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;

