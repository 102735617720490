import {
  Form,
  Input,
  Layout,
  Image,
  Typography,
  Switch,
  DatePicker,
  Upload,
  message,
  Modal,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import LOGO from "../../assets/images/logo.png";
import COLORS from "../../Style/colors";
// import HijriDatePicker from "hijri-date-picker";
import momentHijri from "moment-hijri";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import apiUrl from "../../API/config";
import openNotification from "../../Components/Notifications";
import { useIntl } from "react-intl";
// import moment from "moment";
const { Content } = Layout;
const { Text, Title } = Typography;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];
const { Dragger } = Upload;
const EditStudentPage = ({ student, setShowEdit, onFinishEdit, loading }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [showHijri, setShowHijri] = useState(false);
  const [errorSizeImage, setErrorSizeImage] = useState(false);
  const [coverImages, setCoverImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [errorSizeCover, setErrorSizeCover] = useState(false);

  useEffect(() => {
    if (student?.image) {
      setCoverImages([
        {
          uid: student?._id,
          status: "done",
          url: student?.image,
          name: `image-one`,
        },
      ]);
    }
  }, [student]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error("الحد الأعلى هو ٢ ميجا بايت");
        return false;
      }
    }

    return true;
  };
  // handle image upload;
  const handleChangeImages = ({ fileList, file }, type) => {
    let newFileList = [...fileList];
    setErrorSizeImage(false);
    let errors = [];
    newFileList.forEach((el) => {
      if (el.size > 2000000) {
        //check if the image size is bigger than 2Mo
        errors.push(el?.uid);
      }
    });
    if (errors.length > 0) {
      //return error if there is images bigger than 2Mo

      setErrorSizeImage(true);

      return;
    }
    if (file.status === "uploading") {
      setLoadingImage(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoadingImage(false); // hide loading;
      console.log(file.response);
      if (file?.response?.path) {
        form.setFieldsValue({
          image: file.response.path,
        });
      }
    } else if (file.status === "error") {
      setLoadingImage(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }

    setCoverImages([file]);
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Modal
        open={previewVisible}
        title={student?.nameAr}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt={student?.nameAr}
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      <Content
        style={{
          flex: 2,
          display: "flex",
          //   justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: COLORS.primary,
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}
        >
          <Text
            className='my-font'
            style={{
              textAlign: "center",
              color: "#fff",
              width: "60%",
            }}
          >
            {student?.nameAr}
          </Text>
          <Image
            preview={false}
            width={50}
            src={LOGO}
            style={{
              borderRadius: "10px",
            }}
          />
        </div>
        <Text
          className='my-font'
          style={{
            textAlign: "right",
            color: COLORS.black,
            marginTop: "20px",
          }}
        >
          نرجو التحقق من البيانات الخاصة بك ، والتأكد من صحتها ، وفي حال وجود
          تعديل عليها نرجو إرفاق صورة من الجواز أو صورة من الهوية
        </Text>
        <Form
          name='student-form-edit'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinishEdit}
          initialValues={{
            nameAr: student?.nameAr,
            nameEn: student?.nameEn,
            idNumber: student?.idNumber,
            dateOfBirthHejri: student?.dateOfBirthHejri,
            dateOfBirthGregorian: student?.dateOfBirthGregorian
              ? moment(student?.dateOfBirthGregorian, "DD/MM/YYYY")
              : null,
            image: student?.image,
            _id: student?._id,
          }}
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          {() => {
            console.log("student", form.getFieldValue("isCorrect"));
            return (
              <div>
                <Form.Item
                  name='nameAr'
                  label='الاسم العربي'
                  rules={[
                    {
                      required: true,
                      message: "الرجاء إدخال الاسم العربي",
                    },
                  ]}
                >
                  <Input
                    disabled={true}
                    placeholder='الاسم العربي'
                    style={{
                      // Add shadows to create the "card" effect
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      minHeight: "50px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='nameEn'
                  label='الاسم الانجليزي'
                  rules={[
                    {
                      required: true,
                      message: "الرجاء إدخال الاسم الانجليزي",
                    },
                  ]}
                >
                  <Input
                    disabled={true}
                    placeholder='الاسم الانجليزي'
                    style={{
                      // Add shadows to create the "card" effect
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      minHeight: "50px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='idNumber'
                  disabled={true}
                  label='رقم الهوية'
                  rules={[
                    {
                      required: true,
                      message: "الرجاء إدخال رقم الهوية",
                    },
                  ]}
                >
                  <Input
                    placeholder='رقم الهوية'
                    style={{
                      // Add shadows to create the "card" effect
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      minHeight: "50px",
                    }}
                    disabled={true}
                  />
                </Form.Item>
                {/* Add radio button to select type of date */}

                <Form.Item
                  name='dateOfBirthGregorian'
                  label='تاريخ الميلاد بالتاريخ الميلادي'
                  rules={[
                    {
                      required: true,
                      message: "الرجاء إدخال تاريخ الميلاد",
                    },
                  ]}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DatePicker
                    disabled={true}
                    onChange={(e) => {
                      const date = moment(e).format("YYYY/MM/DD");
                      const date2 = momentHijri(date, "YYYY/MM/DD").format(
                        "iDD/iMM/iYYYY"
                      );
                      form.setFieldsValue({
                        dateOfBirthHejri: date2,
                      });
                    }}
                    placeholder='تاريخ الميلاد'
                    style={{
                      // Add shadows to create the "card" effect
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  />
                </Form.Item>
                <FormItem
                  disabled={true}
                  name='dateOfBirthHejri'
                  label=' تاريخ الميلاد بالتاريخ الهجري بالصيغة 25/01/1435'
                  onChange={(e) => {
                    if (e.target.value.length === 10) {
                      let date = momentHijri(
                        e.target.value,
                        "iDD/iMM/iYYYY"
                      ).format("YYYY/MM/DD");
                      let date2 = moment(date, "YYYY/MM/DD");
                      form.setFieldsValue({
                        dateOfBirthGregorian: date2,
                      });
                    }
                  }}
                  rules={[
                    {
                      required: true,
                      message: "الرجاء إدخال تاريخ الميلاد",
                    },
                    // the format should be like this nn/nn/nnnn
                    {
                      pattern:
                        /^([1-9]|0[1-9]|[12][0-9]|3[01])\/([1-9]|0[1-9]|1[012])\/(1[0-9]{3}|2[0-9]{3})$/,
                      message: "الرجاء إدخال التاريخ بالشكل الصحيح",
                    },
                  ]}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Input
                    disabled={true}
                    placeholder='30/12/1434'
                    style={{
                      // Add shadows to create the "card" effect
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      minHeight: "40px",
                    }}
                  />
                  {/* Add a show text info */}
                </FormItem>
                {/* Add file input  */}
                <Form.Item
                  name='isCorrect'
                  label='هل البيانات صحيحة'
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Radio.Group>
                    <Radio value={true}>نعم</Radio>
                    <Radio value={false}>لا</Radio>
                  </Radio.Group>
                </Form.Item>
                {form.getFieldValue("isCorrect") === false ? (
                  <Form.Item
                    name='imageone'
                    label='نرجو رفع صورة من الجواز أو الهوية في حال كانت البيانات غير صحيحة '
                  >
                    <Dragger
                      action={`${apiUrl}/docs/${student?._id}`}
                      // name='picture'
                      // className='avatar-uploader'
                      // showUploadList={false}
                      // accept='image/jpg,image/jpeg,image/png,application/pdf'
                      // listType='picture-card'
                      // onChange={(e) => handleChangeImages(e, "cover")}
                      // // onPreview={onPreview}
                      // beforeUpload={beforeUpload}
                      // multiple={false}
                      // maxCount={0}
                      // fileList={coverImages}

                      name='picture'
                      fileList={coverImages}
                      onRemove={(e) => {
                        // deleteImage(e, "cover");
                        //   updateShowCoverImageInMenu();
                      }}
                      listType='picture'
                      onChange={(e) => handleChangeImages(e, "cover")}
                      onPreview={onPreview}
                      beforeUpload={beforeUpload}
                      multiple={false}
                      maxCount={0}
                      accept='image/jpg,image/jpeg,image/png,video/mp4'
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <InboxOutlined
                          style={{
                            color: errorSizeCover
                              ? COLORS.danger
                              : COLORS.primary,
                            fontSize: "2rem",
                          }}
                          className='mx-3'
                        />
                        <Text
                          style={{
                            fontSize: "1rem",
                            color: errorSizeCover
                              ? COLORS.danger
                              : COLORS.primary,
                          }}
                          className='my-font'
                        >
                          {coverImages?.length === 0
                            ? "اضف الصورة"
                            : intl.formatMessage({ id: "change" })}
                        </Text>
                      </div>
                    </Dragger>
                  </Form.Item>
                ) : null}

                <CustomButton
                  type='primary'
                  htmlType='submit'
                  className='my-font mt-3'
                  loading={loading}
                  text={"تأكيد"}
                  disabled={
                    form.getFieldValue("isCorrect") !== false &&
                    form.getFieldValue("isCorrect") !== true
                  }
                  style={{
                    // Add shadows to create the "card" effect
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    width: "100%",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                />
                <CustomButton
                  type='secondary'
                  className='my-font mt-3'
                  loading={loading}
                  text={"رجوع"}
                  style={{
                    // Add shadows to create the "card" effect
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    width: "100%",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                  onClick={() => setShowEdit(false)}
                />
              </div>
            );
          }}
        </Form>
      </Content>
    </Layout>
  );
};

export default EditStudentPage;
