import { createSlice,  } from "@reduxjs/toolkit";

const initialState = {
  local: "ar",
  rtl: true,
  isOffline: false,
  fullWidth: window.innerWidth,
  isBigScreen: true,
  didFetched: false,
  notificationText: ''
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeLanguage: (state) => {
      if (state.local === "ar") {
        state.local = "en";
        state.rtl = false;
      } else if(state?.local === 'en') {
        state.local = "ar";
        state.rtl = true;
      }
    },
    setIsOffline: (state, action) => {
      state.isOffline = action.payload;
    },
    // handleToggleSidebar: (state, action) => {
    //   state.toggled = action.payload;
    // },
    handleCollapsedChange : (state,) => {
      state.collapsed = !state.collapsed;
    },
    sefFullWidth : (state, action) => {
      state.fullWidth = action.payload.fullWidth;
      state.isBigScreen = action.payload.isBigScreen;
    },
    // setDidFetched: (state) => {
    //   state.didFetched = true;
    // },
    clearSettings: (state) => {
      state.local= "en"
      state.rtl= false
      state.isOffline= false
      // state.collapsed= false
      // state.toggled= false
      state.fullWidth= window.innerWidth
      state.isBigScreen= true
      state.didFetched= false
    },
    setNotificationText: (state, action) => {
      state.notificationText = action.payload;
    },
    
  },
});

export const {
  changeLanguage: changeLanguageAction,
  setIsOffline: setIsOfflineAction,
  setNotificationText: setNotificationTextAction,
  // handleCollapsedChange: handleCollapsedChangeAction,
  sefFullWidth: sefFullWidthAction,
  // setDidFetched: setDidFetchedAction,
  clearSettings: clearSettingsAction,
} = settingsSlice.actions;

export default settingsSlice.reducer;
