import React from "react";
import { Menu, Typography } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { logOutAction } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  IoHomeOutline,
  IoLogOut,
  IoPeopleOutline,
  IoInformationCircleOutline,
  IoBanSharp,
} from "react-icons/io5";
import { SettingOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { MdDoNotDisturbOnTotalSilence, MdWatchLater } from "react-icons/md";
import COLORS from "../../Style/colors";
import { GrEmptyCircle, GrUserAdmin, GrWaypoint } from "react-icons/gr";
import {
  BiFoodMenu,
  BiFoodTag,
  BiMessageSquareError,
  BiQrScan,
} from "react-icons/bi";
const { Text } = Typography;
const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  if (user?.role === "activity_teacher") {
    return (
      <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
        <Menu.Item
          key='home'
          icon={<IoHomeOutline size={20} />}
          onClick={() => {
            // toggleSideBar();
          }}
        >
          <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='students'
          icon={<IoPeopleOutline size={20} />}
        >
          <Link to='/students-points'>
            {intl.formatMessage({ id: "students" })}
          </Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='qr-reader'
          icon={<BiQrScan size={20} />}
        >
          <Link to='/qr'>{"الكيو ار"}</Link>
        </Menu.Item>
        <Menu.Item
          key='signOut'
          onClick={() => {
            dispatch(logOutAction());
            // toggleSideBar();
          }}
          icon={<IoLogOut size={20} />}
        >
          <Text className='my-font'>
            {intl.formatMessage({ id: "signout" })}
          </Text>
        </Menu.Item>
      </Menu>
    );
  }

  if (user?.name === "الفايز" || user?.name === "ماجد") {
    return (
      <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
        <Menu.Item
          key='home'
          icon={<IoHomeOutline size={20} />}
          onClick={() => {
            // toggleSideBar();
          }}
        >
          <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='latents'
          icon={<MdWatchLater size={20} />}
        >
          <Link to='/latents'>{intl.formatMessage({ id: "latents" })}</Link>
        </Menu.Item>
        <Menu.Item
          key='signOut'
          onClick={() => {
            dispatch(logOutAction());
            // toggleSideBar();
          }}
          icon={<IoLogOut size={20} />}
        >
          <Text className='my-font'>
            {intl.formatMessage({ id: "signout" })}
          </Text>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
      <Menu.Item
        key='home'
        icon={<IoHomeOutline size={20} />}
        onClick={() => {
          // toggleSideBar();
        }}
      >
        <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='students'
        icon={<IoPeopleOutline size={20} />}
      >
        <Link to='/students'>{intl.formatMessage({ id: "students" })}</Link>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='absence'
        icon={<MdDoNotDisturbOnTotalSilence size={20} />}
      >
        <Link to='/absence'>{intl.formatMessage({ id: "absence" })}</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='latents'
        icon={<MdWatchLater size={20} />}
      >
        <Link to='/latents'>{intl.formatMessage({ id: "latents" })}</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='whtsapp'
        icon={<WhatsAppOutlined size={20} />}
      >
        <Link to='/whtsapp'>إرسال رسالة </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key=''
        icon={<BiMessageSquareError size={20} color={COLORS.danger} />}
      >
        <Link
          to='/behavioral-report'
          style={{
            color: COLORS.danger,
          }}
        >
          المخالفات السلوكية
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='absence-report'
        icon={<IoBanSharp size={20} color={COLORS.danger} />}
      >
        <Link
          to='/absence-report'
          style={{
            color: COLORS.danger,
          }}
        >
          تقرير الغياب
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          // toggleSideBar();
        }}
        key='latents-report'
        icon={<IoInformationCircleOutline size={20} color={COLORS.warning} />}
      >
        <Link
          to='/latents-report'
          style={{
            color: COLORS.warning,
          }}
        >
          تقرير التأخير
        </Link>
      </Menu.Item>
      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='settings'
          icon={<SettingOutlined size={20} />}
        >
          <Link to='/setting'>إعداد الواتساب</Link>
        </Menu.Item>
      ) : null}
      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='students-points'
          icon={<IoPeopleOutline size={20} />}
        >
          <Link to='/students-points'>
            {intl.formatMessage({ id: "students" })}
          </Link>
        </Menu.Item>
      ) : null}
      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='adminSMS'
          icon={<GrUserAdmin size={20} />}
        >
          <Link to='/adminSMS'>رسائل الإدارة</Link>
        </Menu.Item>
      ) : null}
      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='waitingClass'
          icon={<GrWaypoint size={20} />}
        >
          <Link to='/waitingClass'>حصص الانتظار </Link>
        </Menu.Item>
      ) : null}
      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            // toggleSideBar();
          }}
          key='dailyWatch'
          icon={<BiFoodMenu size={20} />}
        >
          <Link to='/dailyWatch'>الإشراف اليومي </Link>
        </Menu.Item>
      ) : null}

      <Menu.Item
        key='signOut'
        onClick={() => {
          dispatch(logOutAction());
          // toggleSideBar();
        }}
        icon={<IoLogOut size={20} />}
      >
        <Text className='my-font'>{intl.formatMessage({ id: "signout" })}</Text>
      </Menu.Item>
    </Menu>
  );
};

export default SuperUserSide;
