import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Typography, Select } from "antd";
import dayjs from "dayjs";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import ALL from "../allMessagesWithNumbers.json";
import { sendMessages } from "../../API/fetch";
const { Text, Title } = Typography;

const englishDaysToArabic = (day) => {
  switch (day) {
    case "Sunday":
      return "الأحد";
    case "Monday":
      return "الإثنين";
    case "Tuesday":
      return "الثلاثاء";
    case "Wednesday":
      return "الأربعاء";
    case "Thursday":
      return "الخميس";
    case "Friday":
      return "الجمعة";
    case "Saturday":
      return "السبت";
    default:
      return "";
  }
};
const Home = () => {
  const { students } = useSelector((state) => state.students);
  const [data, setData] = useState([]);
  const [late, setLate] = useState([]);
  const [loading, setLoading] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState("last7Days");
  const convertArabicToEnglish = (string) => {
    let arabic = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    // loop throw the string and replace any number with english number;
    let english = string;
    arabic.forEach((number, index) => {
      english = english.replace(new RegExp(number, "g"), index);
    });
    return english;
  };
  useEffect(() => {
    // get the last 7 days
    let daysSelected = [];
    if (selectedFilter === "last7Days") {
      for (let i = 0; i < 7; i++) {
        // if the day is friday or saturday skip it
        if (
          dayjs().subtract(i, "days").format("dddd") === "Friday" ||
          dayjs().subtract(i, "days").format("dddd") === "Saturday"
        ) {
          continue;
        }
        daysSelected.push(dayjs().subtract(i, "days").format("YYYY-MM-DD"));
      }
    } else if (selectedFilter === "last14Days") {
      for (let i = 0; i < 14; i++) {
        if (
          dayjs().subtract(i, "days").format("dddd") === "Friday" ||
          dayjs().subtract(i, "days").format("dddd") === "Saturday"
        ) {
          continue;
        }
        daysSelected.push(dayjs().subtract(i, "days").format("YYYY-MM-DD"));
      }
    } else if (selectedFilter === "last30Days") {
      for (let i = 0; i < 30; i++) {
        if (
          dayjs().subtract(i, "days").format("dddd") === "Friday" ||
          dayjs().subtract(i, "days").format("dddd") === "Saturday"
        ) {
          continue;
        }
        daysSelected.push(dayjs().subtract(i, "days").format("YYYY-MM-DD"));
      }
    }
    // get the number of absent students for each day
    let absentStudents = daysSelected.map((day) => {
      let absent = students.filter((student) => {
        // convert all days indide absenceDays to english
        let days = student?.absenceDays?.map((day) => {
          return convertArabicToEnglish(day);
        });
        return days?.includes(day);
      });
      return absent.length;
    });
    // get late days;
    let lateDays = daysSelected.map((day) => {
      let late = students.filter((student) => {
        let days = student?.latentsDays?.map((day) => {
          return convertArabicToEnglish(day);
        });
        return days?.includes(day);
      });
      return late.length;
    });
    setLate({
      labels: daysSelected.map((date) => {
        // return dd-mm + day name
        return `${dayjs(date).format("DD-MM")} ${englishDaysToArabic(
          dayjs(date).format("dddd")
        )}`;
      }),
      datasets: [
        {
          label: "التأخير",
          data: lateDays,
          fill: false,
          backgroundColor: COLORS.warning,
          borderColor: COLORS.warning,
        },
      ],
    });

    // set the data to be used in the chart
    setData({
      labels: daysSelected.map((date) => {
        // return dd-mm + day name
        return `${dayjs(date).format("DD-MM")} ${englishDaysToArabic(
          dayjs(date).format("dddd")
        )}`;
      }),
      datasets: [
        {
          label: "الغياب",
          data: absentStudents,
          fill: false,
          backgroundColor: COLORS.danger,
          borderColor: COLORS.danger,
        },
      ],
    });
  }, [students, selectedFilter]);

  // const sendExam = async () => {
  //   console.log("send exam");
  //   console.log(ALL.length);
  //   setLoading(true);
  //   let formattedMessageWithPhone = [];
  //   ALL.forEach((phone, index) => {
  //     let num = index + 1;
  //     if (num > 500 && num <= 750) {
  //       formattedMessageWithPhone.push({
  //         phone: phone.phone,
  //         message: phone.message,
  //       });
  //     }
  //   });
  //   // console.log(formattedMessageWithPhone[0]);
  //   const data = {
  //     phones: formattedMessageWithPhone,
  //     senderName: "Whatsapp",
  //     studentIds: [],
  //     type: "GENERAL",
  //   };
  //   try{

  //     const res = await sendMessages(data);
  //     console.log(res.data);
  //     setLoading(false)
      
  //   }catch(error){
  //     setLoading(false)
  //     console.log(error);
  //   }
  // };
  return (
    <Row
      style={{
        minHeight: "calc(100vh - 100px)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {/* Add select date 
        last 7 days
        last 14 days
        last 30 days
      */}
      {/* <CustomButton text={"send exam"} onClick={sendExam} loading={loading} /> */}
      <Text className='my-font'>اختر مدة التحليل</Text>
      <Select
        defaultValue='last7Days'
        style={{ width: 120 }}
        onChange={(value) => setSelectedFilter(value)}
      >
        <Select.Option value='last7Days'>الأسبوع الأخير</Select.Option>
        <Select.Option value='last14Days'>الأسبوعين الأخيرين</Select.Option>
        <Select.Option value='last30Days'>الشهر الأخير</Select.Option>
      </Select>
      <Row
        style={{
          margin: "20px",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
          flex: 1,
          //
        }}
      >
        {/* add title of the card */}
        <Title level={5}>الغياب</Title>
        {/* Display chart of the last 7 days of how many student absenct */}
        {data && data.labels && data.labels.length ? (
          <Chart type='bar' data={data} />
        ) : null}
      </Row>
      <Row
        style={{
          margin: "20px",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
          flex: 1,
          //
        }}
      >
        {/* add title of the card */}
        <Title level={5}>التأخير</Title>
        {/* Display chart of the last 7 days of how many student absenct */}
        {late && late.labels && late.labels.length ? (
          <Chart type='bar' data={late} />
        ) : null}
      </Row>
    </Row>
  );
};

export default Home;
