import {
  Modal,
  Typography,
  Form,
  Spin,
  Row,
  Col,
  Input,
  Divider,
  Tag,
  Checkbox,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getPdfReport, sendMessagesWithReport } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const StudentModalReport = ({ visible, onCancel, student, type }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [withReport, setWithReport] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    if (type === "ABSENCE") {
      setSelectedDates(student?.absenceDays);
    } else {
      setSelectedDates(student?.latentsDays);
    }

    return () => {
      setSelectedDates([]);
    };
  }, [student, type]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let formattedMessageWithPhone = [
        {
          phone: student.phone,
          message: values.message,
        },
      ];

      const data = {
        phones: formattedMessageWithPhone,
        reportData: {
          name: student?.name,
          dates: selectedDates,
          grad: student?.grade,
          type: type === "ABSENCE" ? "absence" : "late",
          file: "report.pdf",
        },
        withReport,
      };

      openNotification({
        title: `سيستغرق ارسال الرسائل 2 ثواني`,
        type: "warning",
        rtl,
      });

      const res = await sendMessagesWithReport(data);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      console.log(err);
    }
  };

  useEffect(() => {
    if (student?._id) {
      setTimeout(() => {
        form.setFieldsValue({
          ...student,
          phone: student?.phone?.replace("966", ""),
        });
      }, 100);
    } else {
      setTimeout(() => {
        form.setFieldsValue({
          name: "",
          phone: "",
          grade: "",
          absenceDays: [],
          latentsDays: [],
          localId: "",
        });
      }, 200);
    }
  }, [visible]);

  // 12/03/2023

  const printReport = async () => {
    console.log("Print");
    setLoading(true);
    try {
      const data = {
        name: student?.name,
        dates: selectedDates,
        grad: student?.grade,
        type: type === "ABSENCE" ? "absence" : "late",
        file: "report.pdf",
      };
      // get the pdf from the server;
      const res = await getPdfReport(data);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${student?.name}-report.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (e) {
      console.log("error");
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <>
          <Title level={5} className='my-font'>
            {student?.name}
          </Title>
          <Text type='secondary'> الشعبة: {student?.grade}</Text>
        </>
      }
      centered
      visible={visible}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          name='student-form'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            message: "",
          }}
        >
          {() => {
            return (
              <>
                {type === "ABSENCE" ? (
                  <>
                    <Title level={5} className='my-font'>
                      مجموع أيام الغياب :
                      <Tag
                        color='red'
                        className='my-font'
                        style={{
                          borderRadius: 5,
                          marginRight: 3,
                        }}
                      >
                        {selectedDates?.length}
                      </Tag>
                    </Title>
                    <Row>
                      {selectedDates?.length === 0 ? (
                        <Col md={12} sm={24} xs={24}>
                          <Text className='my-font'>لا يوجد</Text>
                        </Col>
                      ) : (
                        selectedDates?.map((day) => (
                          <Col key={day} className='mt-2'>
                            <Tag color='red' className='my-font'>
                              <CloseCircleOutlined
                                onClick={() => {
                                  setSelectedDates(
                                    selectedDates.filter((d) => d !== day)
                                  );
                                }}
                                className='mx-3'
                              />
                              {day}
                            </Tag>
                          </Col>
                        ))
                      )}
                    </Row>
                  </>
                ) : (
                  // <Divider />
                  <>
                    <Title level={5} className='my-font'>
                      مجموع أيام التأخر :
                      <Tag
                        color='warning'
                        className='my-font'
                        style={{
                          borderRadius: 5,
                          marginRight: 3,
                        }}
                      >
                        {selectedDates?.length}
                      </Tag>
                    </Title>
                    <Row>
                      {selectedDates?.length === 0 ? (
                        <Col md={12} sm={24} xs={24}>
                          <Text className='my-font'>لا يوجد</Text>
                        </Col>
                      ) : (
                        selectedDates?.map((day) => (
                          <Col key={day} className='mt-2'>
                            <Tag color='warning' className='my-font'>
                              <CloseCircleOutlined
                                onClick={() => {
                                  setSelectedDates(
                                    selectedDates.filter((d) => d !== day)
                                  );
                                }}
                                className='mx-3'
                              />
                              {day}
                            </Tag>
                          </Col>
                        ))
                      )}
                    </Row>
                  </>
                )}
                <Divider />
                <Text
                  underline
                  className='pointer my-font'
                  onClick={() => {
                    let m = "";
                    if (type === "ABSENCE") {
                      m = `عزيزي ولي أمر الطالب ${student?.name}،\n نفيدكم بأن الطالب قد وصل إلى ${selectedDates?.length} يوم غياب\n وهذا يؤثر على تحصيل الطالب\n ونرجو منكم متابعة الطالب والتواصل معنا في حالة الحاجة\n وشكراً لتعاونكم معنا`;
                    } else {
                      m = `عزيزي ولي أمر الطالب ${student?.name}،\n نفيدكم بأن الطالب قد وصل إلى ${selectedDates?.length} يوم تأخير\n وهذا يؤثر على تحصيل الطالب\n ونرجو منكم متابعة الطالب والتواصل معنا في حالة الحاجة\n وشكراً لتعاونكم معنا`;
                    }
                    form.setFieldsValue({
                      message: m,
                    });
                  }}
                >
                  إدراج نص تلقائي
                </Text>
                <Form.Item
                  name='message'
                  label={intl.formatMessage({ id: "message" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea showCount rows={4} />
                </Form.Item>
                <Form.Item
                  name='withReport'
                  // label='إرسال تقرير الغياب'
                  valuePropName='checked'
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Checkbox onChange={(e) => setWithReport(e.target.checked)} />
                  <Text className='my-font mx-2'>
                    إرفاق تقرير الغياب في رسالة الواتساب
                  </Text>
                </Form.Item>
                <Row
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={10}>
                    <CustomButton
                      type='primary'
                      htmlType='submit'
                      disabled={selectedDates?.length === 0}
                      className='my-font mt-3'
                      style={{ width: "100%" }}
                      loading={loading}
                      text={"إرسال الرسالة"}
                    />
                  </Col>
                  <Col span={10}>
                    <CustomButton
                      type='secondary'
                      disabled={selectedDates?.length === 0}
                      onClick={printReport}
                      className='my-font mt-3'
                      style={{ width: "100%" }}
                      loading={loading}
                      text={"طباعة تقرير الغياب"}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
};

export default StudentModalReport;
