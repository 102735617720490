import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoggedIn: false,
  token: "",
  user: null,
  userRole: null,
  userHasLevel: false,
  role: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.user.token;
    },
    setUserHasLevel: (state, action) => {
      state.userHasLevel = action.payload;
    },
    logOut: async (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.waiter = null;
      state.token = "";
      state.userHasLevel = false;
      state.role = "";
      state.subscriptionEnd = false;
      window.location.reload();
    },
  },
});

export const {
  logOut: logOutAction,
  login: loginAction,
  setUserHasLevel: setUserHasLevelAction,
} = authSlice.actions;

export default authSlice.reducer;
