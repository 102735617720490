import React, { useState } from "react";
import { Form, Image, Layout, Typography, Input } from "antd";
import LOGO from "../../assets/images/logo.png";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import axios from "axios";
import COLORS from "../../Style/colors";
import { WhatsAppOutlined } from "@ant-design/icons";
import { getThirdStudentInfo, updateStudentInfo } from "../../API/fetch";
import EditStudentPage from "./EditStudentPage";
import SuccessView from "./SuccessView";

const { Title, Text } = Typography;
const { Content, Header, Footer } = Layout;
const ThirdGradInfo = () => {
  const [form] = Form.useForm();
  // const [form2] = Form.useForm();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [student, setStudent] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await getThirdStudentInfo(values.localId);
      console.log(res.data);
      if (res?.data?.status === "success") {
        setStudent(res?.data?.student);
        setShowEdit(true);
        // openNotification({
        //   title: "تم",
        //   description: "تم العثور على الطالب",
        //   type: "success",
        //   rtl: true,
        // });
      } else {
        openNotification({
          title: "خطأ",
          description: "لم نتمكن من  إيجاد رقم الهوية الخاص بالطالب",
          type: "error",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (err) {
      if (
        err?.response?.status === 403 ||
        err?.response?.data?.status === "notExist"
      ) {
        openNotification({
          title: "خطأ",
          description: "لم نتمكن من  إيجاد رقم الهوية الخاص بالطالب",
          type: "error",
          rtl: true,
        });
      } else {
        openNotification({
          title: "خطأ",
          description: "حدث خطأ ما ، الرجاء المحاولة لاحقاً",
          type: "error",
          rtl: true,
        });
      }
      setLoading(false);
    }
  };

  const onFinishEdit = async (values) => {
    try {
      // check if the data has changed;

      setLoading(true);
      const data = {
        student: {
          ...values,
          dateOfBirthGregorian:
            values.dateOfBirthGregorian.format("DD/MM/YYYY"),
        },
      };
      delete data.student.imageone;
      delete data.student.image;
      // check if the data has changed;
      console.log(values);
      if (values?.isCorrect === false) {
        data.student.status = "changed";
      } else {
        if (student?.status === "pending") {
          data.student.status = "approved";
        }
      }

      const res = await updateStudentInfo(student?._id, data);
      setStudent(res.data.student);
      setShowSuccess(true);
      setShowEdit(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotification({
        title: "خطأ",
        description: "حدث خطأ ما ، الرجاء المحاولة لاحقاً",
        type: "error",
        rtl: true,
      });
    }
  };

  if (showEdit) {
    return (
      <EditStudentPage
        onFinishEdit={onFinishEdit}
        loading={loading}
        student={student}
        setShowEdit={setShowEdit}
      />
    );
  }

  if (showSuccess) {
    return <SuccessView student={student} />;
  }
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Content
        style={{
          flex: 2,
          display: "flex",
          //   justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <Image
          preview={false}
          width={120}
          src={LOGO}
          className='logo-Login'
          style={{
            // Add shadows to create the "card" effect
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
          }}
        />
        <Title
          style={{
            textAlign: "center",
          }}
          level={5}
        >
          {" "}
          التحقق من البيانات الخاصة بالطالب
        </Title>

        <Form
          name='student-form'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          {() => {
            return (
              <div>
                <Form.Item
                  name='localId'
                  label={"رقم الهوية الخاص بالطالب"}
                  rules={[
                    {
                      required: true,
                      message: "رقم الهوية مطلوب",
                    },
                  ]}
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </Form.Item>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  className='my-font mt-3'
                  loading={loading}
                  text={"استعلام"}
                  style={{
                    // Add shadows to create the "card" effect
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    width: "100%",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                />
              </div>
            );
          }}
        </Form>
      </Content>
      <Footer
        style={{
          flex: 1,
          backgroundColor: COLORS.primary,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Add whatsaap icon */}
        <div
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderRadius: "10px",
          }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=966114713663",
              "_blank"
            );
          }}
        >
          <Text style={{ color: COLORS.primary }}>
            للمساعدة تواصل معنا
            <WhatsAppOutlined
              style={{
                color: COLORS.primary,
                fontSize: "20px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
          </Text>
        </div>
        <Text
          style={{
            color: "#fff",
          }}
          className='my-font'
        >
          جميع الحقوق محفوظة لمجمع الملك عبدالله © {new Date().getFullYear()}
        </Text>
      </Footer>
    </Layout>
  );
};

export default ThirdGradInfo;
