import {
  Modal,
  Typography,
  Form,
  Spin,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Tag,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  createStudent,
  deleteStudentAPI,
  updateStudent,
} from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { checkIfValidPhone } from "../../../helpers";
import COLORS from "../../../../src/Style/colors";
import { deleteStudentAction } from "../../../redux/reducers/studentsReducer";
const grades = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
];
const { Title, Text } = Typography;
const { Option } = Select;
const StudentModal = ({ visible, onCancel, student }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      let total = 0;
      if(student?.points){
        total = parseInt(student?.points)
      }
      total += parseInt(values.newPoints)
        
      const data = {
        student: {
          points: total,
        },
      };
      const res = await updateStudent(student._id, data);
      if (res.data) {
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: "تم التعديل بنجاح",
          type: "success",
          rtl,
        });
        onCancel();
      }else{
        openNotification({
          type: 'error',
          title: 'حدث خطأ ما',
          description: 'حاول مرة أخرى',
          rtl,
        })
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      openNotification({
        type: 'error',
        title: 'حدث خطأ ما',
        description: 'حاول مرة أخرى',
        rtl,
      })
    }
  };

  useEffect(() => {
    if (student?._id) {
      setTimeout(() => {
        form.setFieldsValue({
          ...student,
          phone: student?.phone?.replace("966", ""),
        });
      }, 100);
    }
  }, [visible]);

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "edit" })}
        </Title>
      }
      centered
      open={visible}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          name='student-form'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            ...student,
            phone: student?.phone?.replace("966", ""),
          }}
        >
          {() => {
            return (
              <>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "justName" })}
                      className='mb-1'
                      name='name'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        disabled={true}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "grade" })}
                      className='mb-1'
                      name='grade'
                      style={{ borderRadius: 20 }}
                    >
                      <Select
                        disabled={true}
                        className='my-font'
                        style={{ width: "100%" }}
                        placeholder={intl.formatMessage({ id: "select" })}
                      >
                        {grades.map((grade) => (
                          <Option className='my-font' key={grade} value={grade}>
                            {grade}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"النقاط المسجلة للطلاب"}
                      className='mb-1'
                      name='points'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"النقاط الجديدة"}
                      className='mb-1'
                      name='newPoints'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <CustomButton
                  type='primary'
                  htmlType='submit'
                  className='my-font mt-3'
                  style={{ width: "100%" }}
                  loading={loading}
                  text={intl.formatMessage({ id: "save" })}
                />
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
};

export default StudentModal;
