import {
  Modal,
  Typography,
  Form,
  Spin,
  Row,
  Col,
  Input,
  Divider,
  Tag,
  Checkbox,
  Result,
  Space,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { behavioralById, updateStudent,  } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { CloseCircleOutlined } from "@ant-design/icons";
import COLORS from "../../../Style/colors";
import dayjs from "dayjs";
import { editStudentAction } from "../../../redux/reducers/studentsReducer";

const { Title, Text } = Typography;
const StudentModalReport = ({ visible, onCancel, student }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      // setLoading(true);
      // let formattedMessageWithPhone = [
      //   {
      //     phone: student.phone,
      //     message: values.message,
      //   },
      // ];
      // const data = {
      //   phones: formattedMessageWithPhone,
      //   reportData: {
      //     name: student?.name,
      //     dates: selectedDates,
      //     grad: student?.grade,
      //     type: type === "ABSENCE" ? "absence" : "late",
      //     file: "report.pdf",
      //   },
      //   withReport,
      // };
      // openNotification({
      //   title: `سيستغرق ارسال الرسائل 2 ثواني`,
      //   type: "warning",
      //   rtl,
      // });
      // const res = await sendMessagesWithReport(data);
      // if (res.data.status === "success") {
      //   form.resetFields();
      //   openNotification({
      //     title: intl.formatMessage({ id: "savedSuccessfully" }),
      //     type: "success",
      //     description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
      //     rtl,
      //   });
      // } else {
      //   openNotification({
      //     title: intl.formatMessage({ id: "error" }),
      //     description: intl.formatMessage({ id: "errorfetch" }),
      //     type: "error",
      //     rtl,
      //   });
      // }
      // setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      console.log(err);
    }
  };

  const deleteBehave = async (behavioralId) => {
    try {
      setLoading(true);
      const res = await behavioralById(behavioralId, student?._id);
      if (res.data) {
        const studentClone = { ...student };
        const behavioralClone = studentClone.behavioral.filter(
          (item) => item._id !== behavioralId
        );
        studentClone.behavioral = behavioralClone;
        dispatch(editStudentAction(studentClone));
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: "تم حذف المخالفة بنجاح",
          rtl,
        });
        onCancel();
      } else {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <>
          <Title level={5} className='my-font'>
            {student?.name}
          </Title>
          <Space direction='vertical' size={3}>
            <Text type='secondary'> الشعبة: {student?.grade}</Text>
            <Text type='secondary'>
              {" "}
              عدد المخالفات : {student?.behavioral?.length}
            </Text>
          </Space>
        </>
      }
      centered
      open={visible}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Spin spinning={loading}>
        {student?.behavioral?.length > 0 ? (
          student?.behavioral?.map((item, index) => {
            return (
              <Row
                key={item?._id}
                style={{
                  backgroundColor: COLORS.gray,
                  marginBottom: 10,
                  padding: "10px 20px",
                  borderRadius: 10,
                }}
              >
                <Space
                  direction='horizontal'
                  justify='space-between'
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Title level={5} className='my-font'>
                    {item?.type}
                  </Title>
                  <Tag color='red'>
                    {dayjs(item?.date).format("YYYY-MM-DD")}
                  </Tag>
                </Space>
                <Text level={5} className='my-font'>
                  *{item?.text}
                </Text>
                <Space
                  direction='horizontal'
                  justify='space-between'
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Popconfirm
                    title={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "confirmDelete" })}
                      </Text>
                    }
                    okText={intl.formatMessage({ id: "delete" })}
                    okButtonProps={{
                      style: { color: COLORS.danger, fontFamily: "Cairo" },
                    }}
                    okType='text'
                    cancelText={intl.formatMessage({ id: "cancel" })}
                    cancelButtonProps={{
                      style: { border: "none", fontFamily: "Cairo" },
                    }}
                    onConfirm={() => deleteBehave(item?._id)}
                    loading={loading}
                  >
                    <Text type='danger' className='pointer my-font'>
                      {intl.formatMessage({ id: "delete" })}
                    </Text>
                  </Popconfirm>

                  <Tag color='gray' className='my-font'>
                    الدرجة المخصومة : {item?.grade}
                  </Tag>
                </Space>
              </Row>
            );
          })
        ) : (
          <Result status={"success"} title='لا يوجد مخالفات سلوكية' />
        )}
      </Spin>
    </Modal>
  );
};

export default StudentModalReport;
