import {
  Modal,
  Typography,
  Form,
  Spin,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Tag,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createStudent, deleteStudentAPI, updateStudent } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { checkIfValidPhone } from "../../../helpers";
import COLORS from '../../../../src/Style/colors';
import { deleteStudentAction } from "../../../redux/reducers/studentsReducer";
const grades = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
];
const { Title, Text } = Typography;
const { Option } = Select;
const StudentModal = ({ visible, onCancel, student }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const {user} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (isNew) {
        const data = {
          students: [
            {
              name: values.name,
              phone: `966${values.phone}`,
              grade: values.grade,
              localId: values.localId,
            },
          ],
        };
        const res = await createStudent(data);
        if (res.data) {
          openNotification({
            title: intl.formatMessage({ id: "success" }),
            description: "تمت الاضافة بنجاح",
            type: "success",
            rtl,
          });
          onCancel();
        }
      } else {
        const data = {
          student: {
            name: values.name,
            phone: `966${values.phone}`,
            grade: values.grade,
            localId: values.localId,
          },
        };
        const res = await updateStudent(student._id, data);
        if (res.data) {
          openNotification({
            title: intl.formatMessage({ id: "success" }),
            description: "تم التعديل بنجاح",
            type: "success",
            rtl,
          });
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (student?._id) {
      setIsNew(false);
      setTimeout(() => {
        form.setFieldsValue({
          ...student,
          phone: student?.phone?.replace("966", ""),
        });
      }, 100);
    } else {
      setIsNew(true);
      setTimeout(() => {
        form.setFieldsValue({
          name: "",
          phone: "",
          grade: "",
          absenceDays: [],
          latentsDays: [],
          localId: "",
        });
      }, 200);
    }
  }, [visible]);

  // 12/03/2023

  const deleteStudent = async () => {
    console.log("delete")
    try{
      setLoading(true)
      const res = await deleteStudentAPI(student._id)
      if(res.data){
        openNotification({
          title: "success",
          description: "تم الحذف بنجاح",
          type: "success",
          rtl,
        });
        dispatch(deleteStudentAction(student._id))
        onCancel();
      }
      setLoading(false)

    }catch(err){
      console.log(err)
      setLoading(false)
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "حدث خطأ ما",
        type: "error",
        rtl,
      });
    }
  }
  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "edit" })}
        </Title>
      }
      centered
      visible={visible}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          name='student-form'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={
            isNew
              ? {
                  name: "",
                  phone: "",
                  grade: "",
                  absenceDays: [],
                  latentsDays: [],
                  localId: "",
                }
              : {
                  ...student,
                  phone: student?.phone?.replace("966", ""),
                }
          }
        >
          {() => {
            return (
              <>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "justName" })}
                      className='mb-1'
                      name='name'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                        disabled={user?.role !== "admin"}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='phone'
                      label={intl.formatMessage({ id: "phone" })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorphone" }),
                        },
                        {
                          validator: (_, value) => {
                            let valid = checkIfValidPhone(`+966${value}`);
                            if (valid) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        addonBefore={rtl ? null : <Text>966</Text>}
                        addonAfter={rtl ? <Text>966</Text> : null}
                        disabled={user?.role !== "admin"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "grade" })}
                      className='mb-1'
                      name='grade'
                      style={{ borderRadius: 20 }}
                    >
                      <Select
                        className='my-font'
                        style={{ width: "100%" }}
                        placeholder={intl.formatMessage({ id: "select" })}
                        disabled={user?.role !== "admin"}
                      >
                        {grades.map((grade) => (
                          <Option className='my-font' key={grade} value={grade}>
                            {grade}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "localId" })}
                      className='mb-1'
                      name='localId'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                        disabled={user?.role !== "admin"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider>
                  <Title level={4} className='my-font'>
                    {intl.formatMessage({ id: "absence" })}
                  </Title>
                </Divider>
                <Row>
                  {isNew ? (
                    <Col md={12} sm={24} xs={24}>
                      <Text className='my-font'>لا يوجد</Text>
                    </Col>
                  ) : (
                    student?.absenceDays?.map((day) => (
                      <Col className='mt-2'>
                        <Tag color='red' className='my-font'>
                          {day}
                        </Tag>
                      </Col>
                    ))
                  )}
                </Row>
                <Divider>
                  <Title level={4} className='my-font'>
                    {intl.formatMessage({ id: "latents" })}
                  </Title>
                </Divider>
                <Row>
                  {isNew ? (
                    <Col md={12} sm={24} xs={24}>
                      <Text className='my-font'>لا يوجد</Text>
                    </Col>
                  ) : (
                    student?.latentsDays?.map((day) => (
                      <Col className='mt-2'>
                        <Tag color='warning' className='my-font'>
                          {day}
                        </Tag>
                      </Col>
                    ))
                  )}
                </Row>
                <Divider />
                <Popconfirm
                  title={
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "confirmDelete" })}
                    </Text>
                  }
                  
                  okText={intl.formatMessage({ id: "delete" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={deleteStudent}
                  disabled={isNew || loading || user?.role !== "admin"}
                  loading={loading}
                 
                >
                  
                  <Text
                    type='danger'
                    className='pointer my-font'
                    disabled={isNew || loading || user?.role !== "admin"}
                  >
                    {intl.formatMessage({ id: "deleteStudent" })}
                  </Text>
                </Popconfirm>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  className='my-font mt-3'
                  style={{ width: "100%" }}
                  loading={loading}
                  text={intl.formatMessage({ id: "save" })}
                />
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
};

export default StudentModal;
