import axios from 'axios';
import apiUrl from './config';
import store from '../redux/store'

export const api = axios.create({
  timeout: 30000,
  baseURL: apiUrl,
});

// Add a request interceptor (before any request)
api.interceptors.request.use((req) => {
  const token = store.getState()?.auth?.user?.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
 
  if (!req.headers['Content-Type']) {
    req.headers['Content-Type'] = 'application/json';
  }
  req.headers.Accept = 'application/json';

  return req;
});
