import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  Input,
  Upload,
  Tag,
  Select,
  Row,
  Col,
} from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessages, sendMessageWithImage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { FORMATTED_ADMIN_PHONES, roles, rolesColors } from "./data";
import { ALL_GRADES } from "../../Types";

const { Option } = Select;

const { Text, Title } = Typography;
const WaitingClass = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const sendSMS = async (values) => {
    try {
      const selected = FORMATTED_ADMIN_PHONES.find(
        (a) => a.name === values.teacher
      );
      if (!selected || !selected.phone) {
        return openNotification({
          title: "الرجاء اختيار المعلم",
          type: "warning",
          rtl,
        });
        return;
      }
      setLoading(true);
      const message = `المكرم الاستاذ ${values.teacher}\n نفيدكم بوجود حصة انتظار لديكم \n الحصة ( ${values.classTime} ) \n الشعبة ( ${values.grade} ) \n ولكم جزيل الشكر \nثانوية الملك عبدالله`;

      const data = {
        phones: [
          {
            phone: selected.phone,
            message,
          },
        ],
        senderName: "Whatsapp",
        studentIds: [],
        type: "GENERAL",
      };

      const res = await sendMessages(data);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            حصص الانتظار
          </Title>
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendSMS}
          initialValues={{
            senderName: "Whatsapp",
            teacher: "",
            grade: "",
            classTime: "",
          }}
        >
          {() => {
            return (
              <>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='teacher'
                      label='المعلم'
                      rules={[
                        {
                          required: true,
                          message: "الرجاء اختيار المعلم",
                        },
                      ]}
                    >
                      <Select
                        placeholder='المعلم'
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {FORMATTED_ADMIN_PHONES?.map((teacher) => (
                          <Option key={teacher.name} value={teacher.name}>
                            {teacher.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='grade'
                      label='الشعبة'
                      rules={[
                        {
                          required: true,
                          message: "الرجاء اختيار رقم الشعبة",
                        },
                      ]}
                    >
                      <Select
                        placeholder='الشعبة'
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {ALL_GRADES.filter(
                          (a) => a !== "100" && a !== "200" && a !== "300"
                        ).map((grade, i) => (
                          <Option key={grade} value={grade}>
                            {grade}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='classTime'
                      label='الحصة'
                      rules={[
                        {
                          required: true,
                          message: "الرجاء اختيار وقت الحصة",
                        },
                      ]}
                    >
                      <Select
                        placeholder='الحصة'
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {new Array(7).fill(0).map((_, i) => (
                          <Option key={i} value={i + 1}>
                            {i + 1}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5}>صيغة الرسالة</Title>
                    <Text className='my-font' type='secondary'>
                      المكرم الاستاذ{" "}
                      <Text type='primary'>
                        {form.getFieldValue("teacher")}
                      </Text>{" "}
                      نفيدكم بوجود حصة انتظار لديكم الحصة
                      <Text type='primary'>
                        ( {form.getFieldValue("classTime")} )
                      </Text>{" "}
                      الشعبة
                      <Text type='primary'>
                        ( {form.getFieldValue("grade")} )
                      </Text>{" "}
                      ولكم جزيل الشكر ثانوية الملك عبدالله
                    </Text>
                  </Col>
                </Row>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      !form?.getFieldValue("teacher") ||
                      !form?.getFieldValue("grade") ||
                      !form?.getFieldValue("classTime")
                    }
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>
      </Spin>
    </div>
  );
};

export default WaitingClass;
