import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin, Button, Space, Tag } from "antd";
import { useIntl } from "react-intl";
import { EditOutlined, FileExcelOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { addStudents, getStudents } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import { customerExport } from "../../helpers";
import { setStudentsAction } from "../../redux/reducers/studentsReducer";
import * as XLSX from "xlsx";
import StudentModal from "./Components/StudentModal";
import { ALL_GRADES, COLOR_TAGS_BY_GRADE } from "../../Types";
import { logOutAction } from "../../redux/reducers/authReducer";

const { Text, Title } = Typography;
const Students = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { students } = useSelector((state) => state.students);
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [formattedStudents, setFormattedStudents] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [filterStudents, setFilterStudents] = useState([])
  const [searchValue, setSearchValue] = useState('')
  useEffect(() => {
    if (!students?.length) {
      // getUsersData();
    } else {
      setLoading(false);
    }
  }, [students]);

  const getUsersData = async () => {
    try {
      setLoading(true);
      const res = await getStudents();
      if (res?.data?.students) {
        dispatch(setStudentsAction(res.data.students));
        setLoading(false);
        openNotification({
          title: "success",
          description: "users loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      if (error?.response?.data === "Unauthorized") {
        dispatch(logOutAction())
      } else {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      let formatted = [];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // console.log(dataParse);
      // format should be like this ==> 966509111136
      dataParse.forEach((arr, index) => {
        if (index === 0) return;
        // console.log(arr)
        if (arr[0] && arr[1] && arr[2] && `${arr[2]}`.length === 9 ) {
            if(arr[3]){
              formatted.push({
                name: arr[1],
                phone: `966${arr[2]}`,
                localId: arr[0],
                otherPhone: arr[3],
                grade: arr[4],
              });
            }else{
              formatted.push({
                name: arr[1],
                phone: `966${arr[2]}`,
                localId: arr[0],
                 grade: arr[4],
              });
            }
         

        }
      });
      // console.log(formatted);
      addStudentsToDB(formatted);
    };
    reader.readAsBinaryString(f);
  };

  const addStudentsToDB = async (students) => {
    try {
      setLoading(true);
      const res = await addStudents({students});
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        getUsersData();
        return;
      }
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });

      setLoading(false);
    }
    catch (error) {
      console.log("Errror", error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  }

  // const deleteUser = async (id) => {
  //   try {
  //     setLoading(true);
  //     const res = await deleteUserFromDB(id);
  //     if (res?.data?.status === "success") {
  //       openNotification({
  //         title: intl.formatMessage({ id: "savedSuccessfully" }),
  //         type: "success",
  //         rtl,
  //       });
  //       getUsersData();
  //       return;
  //     }
  //     openNotification({
  //       title: "error",
  //       description: "something went wrong",
  //       type: "error",
  //       rtl: true,
  //     });

  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Errror", error);
  //     setLoading(false);
  //     openNotification({
  //       title: "error",
  //       description: "something went wrong",
  //       type: "error",
  //       rtl: true,
  //     });
  //   }
  // };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return (
         <Text>{index+ 1}</Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      // width: 160,
      
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      // width: 150,
      key: "phone",
      render: (phone) => {
        return (
          <Text
            copyable={{
              // remove the first three digits from phone;
              text: phone,
            }}
          >
            {phone}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "grade" }),
      dataIndex: "grade",
      key: "grade",
      render: (grade) => {
        return <Tag color={COLOR_TAGS_BY_GRADE[grade]}>{grade}</Tag>;
      },
      // filter by grads;
      onFilter: (value, record) => {
        return record.grade === value;
      },
      filters: ALL_GRADES.filter((g) => {
        if (user?.role === "admin") return true;
        return user?.grades?.includes(g) || g === "غير محدد";
      }).map((g) => {
        return {
          text: g,
          value: g,
        };
      }),
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      key: "actions",
      // width: 150,
      render: (actions, record) => {
        return (
          <div className='actions-container'>
            {user?.role === 'admin' ? 
            <Button
              type='primary'
              onClick={() => {
                setSelectedStudent(record.userObject);
                setShowAddModal(true);
              }}
              icon={<EditOutlined />}
            />
             : null} 
          </div>
        );
      }
    }
    
  ];

  useEffect(() => {
    setLoading(true);
    if (students?.length) {
      const formatted = students.map((user, i) => {
       
        return {
          key: user._id,
          number: i + 1,
          _id: user._id,
          grade: user?.grade ? user?.grade : "غير محدد",
          name: user.name,
          phone: user?.phone,
          otherPhone: user?.otherPhone,
          userObject: { ...user },
        };
      });
      setFormattedStudents(formatted);
    }
    setLoading(false);
  }, [rtl, students]);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <StudentModal 
        visible={showAddModal}
        onCancel={() => {
          setShowAddModal(false);
          setSelectedStudent({});
        }}
        student={selectedStudent}
      />
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "students" })}
          </Title>
          {user?.role === "admin"  ?
          <Space direction="horizontal" >
          <Button
            className='upload-wrap  border-8'
            onClick={() => {
              const a = document.getElementById("my-upload-excel");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload-excel'
              accept='.xlsx, .xls'
              onChange={onChange}
            />
            <span className='upload-text'>رفع ملف اكسل للطلاب</span>
          </Button>
          <CustomButton 
            text={intl.formatMessage({ id: "addStudent" })}
            type='primary'
            icon={<PlusOutlined />}
            className="border-8"
            onClick={() => setShowAddModal(true)}
          />
          </Space>
          : null}
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getUsersData}
          />
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedStudents?.length === 0,
              value: searchValue,
              onChange: (e) => {
                setSearchValue(e.target.value);
                if(e.target.value?.length){
                  const filtered = formattedStudents.filter((s) => {
                    return s.name.includes(e.target.value) || s.phone.includes(e.target.value)
                  }
                  )
                  setFilterStudents(filtered)
                }else{
                  setFilterStudents([])
                }
              },
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedStudents?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
              customerExport({
                data: formattedStudents,
                columns,
                fileName: "Users Report",
                loadingSetter: setLoading,
              }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedStudents?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={searchValue?.trim()?.length > 1 ? filterStudents : formattedStudents}
          // scroll={{ x: 500 }}
        />
      </Spin>
    </div>
  );
};

export default Students;
