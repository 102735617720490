import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
    settlements: []
};

export const settlementSlice = createSlice({
  name: "settlement",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSettlements: (state, action) => {
      state.settlements = action.payload;
    },
  },
});

export const {
  setSettlements: setSettlementsAction,
} = settlementSlice.actions;

export default settlementSlice.reducer;
