export function arrAdd({arr, newElement}) {
    if(!arr || arr?.length === 0){
        return [newElement];
    }else{
        return [...arr, newElement]
    }
}
export function concatArr({arr, newArr}) {
    if(!arr || arr?.length === 0){
        return newArr;
    }else{
        return [...arr, ...newArr]
    }
}

export function arrClear() {
    return([])
}

export function arrFilter({arr, callback}) {
     return arr.filter(callback)
}

export function arrRemoveByIndex({arr, index}) {
    if(arr.length === 1 && index === 0) return []
    const copy =  copyArray(arr);
    copy.splice(index, 1)
   return copy;
}

export function arrUpdateByIndex({arr, index, newElement}) {
    const copy = copyArray(arr)
    copy.splice(index, 1, newElement)
    return copy
}

export function arrRemoveByKeyValue({arr, key, value}) {
    const i = arr.findIndex(a => a[key] === value);
    if(i !== -1) {
       return arrRemoveByIndex({arr, index: i})
    }else return []
}

export function arrUpdateByKeyValue({arr, key, value, newElement}) {
    const i = arr.findIndex(a => a[key] === value);
    if(i !== -1) {
       return arrUpdateByIndex({arr, index: i, newElement})
    }else return []
}


export const copyArray = (arr) => {
    return JSON.parse(JSON.stringify(arr))
}